<template>
  <div>
    <v-row v-if="load" class="d-flex justify-center mt-2">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
    <div v-else>
      <v-alert
        v-if="e404"
        border="left"
        type="warning"
        class="mx-md-16 mx-6 mt-6 text-center"
        outlined
        prominent
      >Usuario no encontrado</v-alert>
      <div v-else>
        <v-tabs :vertical="$vuetify.breakpoint.xs" v-model="tab" color="primary">
          <v-tab class="text-h6 font-weight-light">CV</v-tab>
          <v-tab class="text-h6 font-weight-light">Historial de postulaciones</v-tab>
          <v-tab class="text-h6 font-weight-light">Comentarios</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="mx-md-8 mt-md-6 mt-4">
            <v-row>
              <v-form ref="form">
                <v-col cols="12">
                  <div class="d-flex">
                    <h1 class="text-h5 font-weight-light"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-user-tie</v-icon>Información personal</h1>
                    <v-spacer></v-spacer>
                    <v-switch
                      v-model="editar"
                      class="mt-n1 mr-md-8"
                      label="Editar"
                      hide-details
                    ></v-switch>
                  </div>
                  <v-divider class="mb-4 mx-md-7"></v-divider>
                  <v-row class="d-flex px-md-8 px-0">
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model.trim="user.nombre"
                        :rules="[rules.required]"
                        label="Nombre/s"
                        hide-details
                        :readonly="!editar"
                        :filled="!editar"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        v-model.trim="user.apellido"
                        :rules="[rules.required]"
                        label="Apellido/s"
                        hide-details
                        :readonly="!editar"
                        :filled="!editar"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="2">
                      <v-text-field
                        v-model.trim="fecha_nacimiento"
                        :rules="[rules.required, rules.date]"
                        label="Fecha de nacimiento"
                        placeholder="dd/mm/aaaa"
                        v-mask="'##/##/####'"
                        validate-on-blur
                        hide-details
                        :readonly="!editar"
                        :filled="!editar"
                        @blur="actualizar_edad()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="1">
                      <v-text-field
                        v-model.trim="edad"
                        label="Edad"
                        hide-details
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.trim="user.cv.nacimiento.lugar"
                        :rules="[rules.required]"
                        label="Lugar de nacimiento"
                        hide-details
                        :readonly="!editar"
                        :filled="!editar"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex px-md-8 px-0">
                    <v-col cols="12" md="9">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model.trim="user.cv.cuil"
                            :rules="[rules.required]"
                            label="CUIL"
                            v-mask="'##-########-#'"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-select
                            v-model="user.tipo_documento"
                            :rules="[rules.required]"
                            :items="tipos_documentos"
                            label="Tipo de documento"
                            :readonly="!editar"
                            :filled="!editar"
                            hide-details
                          ></v-select>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model.trim="user.documento"
                            :rules="[rules.required]"
                            label="Nº documento"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="4">
                          <v-select
                            v-model="user.cv.provincia"
                            :rules="[rules.required]"
                            :items="nombre_provincias"
                            label="Provincia"
                            @change="get_departamentos"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" sm="4">
                          <v-select
                            v-model="user.cv.departamento"
                            :rules="[rules.required]"
                            :items="departamentos"
                            label="Departamento"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-select>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model.trim="user.cv.localidad"
                            :rules="[rules.required]"
                            label="Localidad"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="8">
                          <v-text-field
                            v-model.trim="user.cv.domicilio"
                            :rules="[rules.required]"
                            label="Domicilio actual"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model.trim="user.cv.telefono.celular"
                            :rules="[rules.required]"
                            label="Teléfono celular"
                            v-mask="'###############'"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          >
                            <template v-slot:append>
                              <SendWpp :numero="user.cv.telefono.celular" :default="true" />
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model.trim="user.cv.telefono.alternativo"
                            label="Teléfono alternativo"
                            v-mask="'###############'"
                            hide-details
                            :readonly="!editar"
                            :filled="!editar"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model.trim="email"
                            label="Correo electrónico"
                            hide-details
                            readonly
                            filled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="3" :class="editar ? '' : 'mt-md-8'">
                      <Foto :usuario="user" :view="!editar" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-form>
            </v-row>

            <v-row>
              <v-col>
                <h1 class="d-flex text-h5 font-weight-light mt-2"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-graduation-cap</v-icon>Formación y conocimientos</h1>
                <v-divider class="mb-4 mx-md-8"></v-divider>
                <v-row class="px-4">
                  <v-col cols="12" class="px-md-8 px-0">
                    <v-card>
                      <TablaFormacionAcademica :form_academica="user.cv.formacion" :view="!editar" :postulante_id="id" />
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="mb-4 px-md-8 px-0">
                    <h1 class="d-flex text-h6 font-weight-light mb-1">
                      Otros conocimientos
                    </h1>
                    <v-card>
                      <TablaOtrosConocimientos :otros_conocimientos="user.cv.conocimientos" :view="!editar" :postulante_id="id" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="d-flex">
                  <h1 class="text-h5 font-weight-light"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-briefcase</v-icon>Experiencia laboral</h1>
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="user.cv.sin_exp"
                    class="mr-md-9 mt-0"
                    label="Sin experiencia"
                    :disabled="user.cv.experiencia.length !== 0 || !editar"
                    hide-details
                  ></v-checkbox>
                </div>
                <v-divider class="mb-4 mx-md-8"></v-divider>
                <v-row class="px-4">
                  <v-col cols="12" class="mb-4 px-md-8 px-0">
                    <v-card>
                      <TablaExperienciaLaboral :xp_laboral.sync="user.cv.experiencia" :disabled="user.cv.sin_exp" :view="!editar" :postulante_id="id" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h1 class="d-flex text-h5 font-weight-light"><v-icon v-if="!$vuetify.breakpoint.xs" left>fas fa-folder-open</v-icon>Archivos</h1>
                <v-divider class="mb-4 mx-md-8"></v-divider>
                <v-row class="px-4">
                  <v-col cols="12" class="px-md-8 px-0">
                    <v-card>
                      <Archivos :usuario="user" :view="!editar" :conv="conv" />
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="mb-4 px-md-8 px-0">
                    <h1 class="text-h6 font-weight-light mb-1">Enlaces</h1>
                    <v-card>
                      <Enlaces :usuario="user" :view="!editar" />
                    </v-card>
                  </v-col>
                  <v-col cols="12"></v-col>
                  <v-col cols="6" md="4">
                    <v-select
                      v-model.trim="user.cv.disp_horaria"
                      label="Disponibilidad horaria"
                      append-icon="fas fa-clock"
                      class="pl-md-6"
                      :items="disponibilidad_horaria"
                      :readonly="!editar"
                      :filled="!editar"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model.trim="user.cv.pretension_salarial"
                      v-mask="mask"
                      label="Pretensión salarial"
                      prefix="$"
                      class="pl-md-6"
                      :readonly="!editar"
                      :filled="!editar"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="d-flex justify-center align-center mb-6">
                    <v-icon left>fas fa-car</v-icon> 
                    <v-checkbox
                      v-model="user.cv.carnet_conducir"
                      label="Carnet de conducir"
                      class="ml-4"
                      :readonly="!editar"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="d-flex justify-end mb-4">
                <v-row class="mx-md-1 pt-0 col-xl-11 d-flex justify-end">
                  <template v-if="convocatoria">
                    <RechazarPostulante
                      :id_conv="conv"
                      :convocatoria="convocatoria"
                      :postulante="user"
                      :p_rechazado.sync="post_rechazado"
                    />
                    <SiguienteEtapaIndividual
                      :id_conv="conv"
                      :convocatoria="convocatoria"
                      :postulante="user"
                      :p_rechazado.sync="post_rechazado"
                    />
                  </template>
                  <div v-if="editar" class="ml-4">
                    <Confirmar @action="guardar"/>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="mx-md-8 mt-md-6 mt-4 px-md-4 px-0">
            <h1 class="text-md-h5 text-h6 font-weight-light mb-2">
              Total de búsquedas en las que se postuló: {{ user.postulaciones.length }}
            </h1>
            <v-progress-linear
              v-if="load_post"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <v-list>
              <v-list-item
                v-for="(item, index) in postulaciones"
                :key="index"
                class="py-2"
              >
                <ConvocatoriaCard :item="item" :cv="true" />
              </v-list-item>
            </v-list>
            <div v-if="user.postulaciones.length !== 0" class="text-center">
              <v-btn
                v-if="postulaciones.length === 0"
                color="info"
                :loading="load_post"
                @click="load_historial"
              >Ver</v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <Comentarios :postulante_id="id" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { db } from '../../firebase'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import TablaExperienciaLaboral from '../../components/tablas/TablaExperienciaLaboral'
import TablaFormacionAcademica from '../../components/tablas/TablaFormacionAcademica'
import TablaOtrosConocimientos from '../../components/tablas/TablaOtrosConocimientos'
import Archivos from '../../components/Archivos'
import Enlaces from '../../components/Enlaces'
import SendWpp from '../../components/utils/SendWpp'
import ConvocatoriaCard from '../../components/ConvocatoriaCard'
import Confirmar from '../../components/utils/Confirmar'
import Foto from '../../components/Foto'
import Comentarios from '../../components/Comentarios'
import moment from 'moment'
import RechazarPostulante from '../../components/utils/RechazarPostulante'
import SiguienteEtapaIndividual from '../../components/utils/SiguienteEtapaIndividual'

const currencyMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '.',
  includeThousandsSeparator: true,
  allowNegative: false,
})

export default {
  data() {
    return {
      tab: null,
      user: null,
      id: this.$route.params.id,
      email: this.$route.params.email,
      conv: this.$route.params.conv,
      post_rechazado: false,
      convocatoria: null,
      editar: false,
      load: false,
      load_post: false,
      e404: false,
      fecha_nacimiento: '',
      edad: '',
      color: '',
      nombre_provincias: [],
      disponibilidad_horaria: ['Full Time', 'Part Time'],
      postulaciones: [],
      mask: currencyMask,
      rules: {
        required: value => !!value || 'Campo requerido',
        date: value => moment(value, 'DD/MM/YYYY', true).isValid() || 'Fecha inválida'
      },
    }
  },
  async created() {
    this.load = true
    await db.collection('usuarios').doc(this.id).get()
      .then((doc) => {
        if (doc.exists) {
          this.user = doc.data()
          this.user.id = doc.id
          this.fecha_nacimiento = this.formatDate(this.user.cv.nacimiento.fecha)
          this.edad = moment().diff(this.user.cv.nacimiento.fecha, 'years')
          this.user.postulaciones = this.user.postulaciones.reverse()
        } else {
          this.e404 = true
        }
      })
      .catch((error) => {
        console.log(error)
      })

    // si tiene conv quiere decir que se abrió desde una convocatoria
    if (this.conv && !this.e404) {
      await db.collection('convocatorias').doc(this.conv).get()
        .then((doc) => {
          if (doc.exists) {
            let conv = doc.data()
            // revisa que la convocatoria no se encuentre finalizada y que el postulantese haya postulado en la misma
            const conv_post = this.user.postulaciones.find(post => post.convocatoria == this.conv && post.estado != 'Cancelada por el postulante')
            if (conv.estado != 'Finalizada' && conv_post) {
              this.post_rechazado = conv_post.estado == 'Rechazado'
              this.convocatoria = conv
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    
    this.provincias.forEach(provincia => {
      this.nombre_provincias.push(provincia.nombre)
    })
    this.get_departamentos()
    this.load = false
  },
  components: {
    TablaExperienciaLaboral, TablaFormacionAcademica, TablaOtrosConocimientos, Archivos, Enlaces, SendWpp, ConvocatoriaCard, Confirmar, Foto, Comentarios, RechazarPostulante, SiguienteEtapaIndividual
  },
  computed: {
    ...mapState(['provincias', 'tipos_documentos']),
  },
  methods: {
    actualizar_edad() {
      this.edad = moment().diff(this.parseDate(this.fecha_nacimiento), 'years')
    },
    async load_historial() {
      this.load_post = true
      for (let index = 0; index < this.user.postulaciones.length; index++) {
        const post = this.user.postulaciones[index]

        await db.collection('convocatorias').doc(post.convocatoria).get()
          .then((doc) => {
            if (doc.exists) {
              let postulacion = doc.data()
              postulacion.fecha_desde_format = this.formatDate(postulacion.fecha.desde)
              postulacion.fecha_hasta_format = this.formatDate(postulacion.fecha.hasta)
              if (post.etapa === '') {
                postulacion.etapa = 'Inicial'
              } else {
                postulacion.etapa = post.etapa
              }
              postulacion.fecha_post = this.formatDate(post.fecha_post)
              postulacion.estado = post.estado

              this.postulaciones.push(postulacion)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
      this.load_post = false
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading_screen = true

        let cv = Object.assign({}, this.user.cv)
        cv.completo = true
        cv.nacimiento.fecha = this.parseDate(this.fecha_nacimiento)
        cv.cuil = cv.cuil.replaceAll('-', '')

        let data = {
          nombre: this.user.nombre.toUpperCase(),
          apellido: this.user.apellido.toUpperCase(),
          documento: this.user.documento.replaceAll('.', ''),
          tipo_documento: this.user.tipo_documento,
          cv: cv
        }

        await db.collection('usuarios').doc(this.id).update(data)
          .then(async () => {
            await this.cargar_documento()
            this.edad = moment().diff(this.user.cv.nacimiento.fecha, 'years')
            this.editar = false
            this.$store.dispatch('show_snackbar', {
              text: 'Los cambios se guardaron correctamente',
              color: 'success'
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })

        this.$store.state.loading_screen = false
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    get_departamentos () {
      this.departamentos = []
      if (!this.e404 && this.user.cv.provincia) {
        this.departamentos = this.provincias.find(prov => prov.nombre === this.user.cv.provincia).departamentos
      }
    },
    async cargar_documento() {
      await db.collection('usuarios_registrados').doc(this.id).set({
        documento: this.user.documento.replaceAll('.', ''),
        email: this.email
      })
        .then(() => {
          this.user.documento = this.user.documento.replaceAll('.', '')
        })
        .catch((error) => {
          console.log(error)
        })
    },
  }
}
</script>