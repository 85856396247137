<template>
  <v-dialog
      v-model="dialog"
      width="450"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="rechazado"
          color="error"
          class="ml-4 mb-2"
        >
          No avanza
          <v-icon right>fas fa-ban</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">
          No avanzar postulante
        </v-card-title>
        <v-card-text class="body-1">
          <v-row>
            <v-col cols="12">
              ¿Desea no avanzar a <strong>{{ postulante.nombre }} {{ postulante.apellido }}</strong> en la búsqueda <strong>{{ convocatoria.busqueda }}</strong>?
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model.trim="comentario_texto"
                label="Comentario"
                rows="3"
                hide-details
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-4"
            @click.stop="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar
            @action="rechazar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'
import Confirmar from './Confirmar.vue'

export default {
  data () {
    return {
      dialog: false,
      postulacion: null,
      comentario: null,
      comentario_texto: ''
    }
  },
  props: {
    id_conv: String,
    convocatoria: Object,
    postulante: Object,
    p_rechazado: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['user']),
    rechazado: {
      get () {
        return this.p_rechazado
      },
      set (value) {
        this.$emit('update:p_rechazado', value)
      }
    }
  },
  components: {
    Confirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        // obtiene la postulacion
        this.postulacion = this.postulante.postulaciones.find(post => post.convocatoria == this.id_conv && post.estado != 'Cancelada por el postulante')
        // obtiene el comentario
        this.$store.state.loading_screen = true
        await db.collection('usuarios').doc(this.postulante.id).collection('comentarios').doc(this.id_conv).get()
          .then((doc) => {
            if (doc.exists) {
              this.comentario = doc.data()
              this.comentario_texto = doc.data().comentario
            } else {
              this.comentario = null
              this.comentario_texto = ''
            }
          })
          .catch((error) => {
            this.comentario = null
            this.comentario_texto = ''
          })
        this.$store.state.loading_screen = false
      } else {
        this.comentario = null
        this.comentario_texto = ''
      }
    }
  },
  methods: {
    async rechazar () {
      this.$store.state.loading_screen = true

      // actualzia (o crea) el comentario en caso que el texto no sea nulo
      if (this.comentario_texto) {
        try {
          // si el comentario no existe lo crea
          if (!this.comentario) {
            this.comentario = {
              busqueda: this.convocatoria.busqueda,
              periodo: format_date(this.convocatoria.fecha.desde) + ' - ' + format_date(this.convocatoria.fecha.hasta),
              postulante_id: this.postulante.id,
              etapa: this.postulacion.etapa,
              convocatoria: this.id_conv,
              usuario: this.user.nombre + ' ' + this.user.apellido
            }
          }
          // cambia el comentario por el nuevo
          this.comentario.comentario = this.comentario_texto
          // guarda el comentario en la coleccion del usuario
          await db.collection('usuarios').doc(this.postulante.id).collection('comentarios').doc(this.id_conv).set(this.comentario)
            .then(() => {
              // todo ok
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al actualizar el comentario del postulante: ' + error.message,
                color: 'error'
              })
            })
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error general al actualizar el comentario del postulante: ' + error.message,
            color: 'error'
          })
        }
      }

      // "elimina" al postulante
      try {
        await db.collection('convocatorias').doc(this.id_conv).collection('postulantes').doc(this.postulante.id).update({ estado: false })
          .then(async () => {
            // actualiza el estado del usuario
            await db.collection('usuarios').doc(this.postulante.id).get()
              .then(async (doc) => {
                if (doc.exists) {
                  let postulaciones = doc.data().postulaciones
                  postulaciones.find(post => post.convocatoria == this.id_conv && post.estado != 'Cancelada por el postulante').estado = 'Rechazado'
                  await db.collection('usuarios').doc(this.postulante.id).update({ postulaciones: postulaciones })
                    .then(() => {
                      // todo ok
                      this.dialog = false
                      this.rechazado = true
                      this.$store.dispatch('show_snackbar', {
                        text: 'Cambios guardados correctamente',
                        color: 'success'
                      })
                    })
                    .catch((error) => {
                      this.$store.dispatch('show_snackbar', {
                        text: 'El postulante fue rechazado de la convocatoria pero no se pudo actualizar su perfil: ' + error.message,
                        color: 'wearning',
                        timeout: 4000
                      })
                    })
                } else {
                  this.$store.dispatch('show_snackbar', {
                    text: 'El postulante fue rechazado de la convocatoria pero no se encontró su perfi',
                    color: 'wearning'
                  })
                }
              })
              .catch((error) => {
                this.$store.dispatch('show_snackbar', {
                  text: 'El postulante fue rechazado de la convocatoria pero no se pudo obtener su perfi: ' + error.message,
                  color: 'wearning'
                })
              })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un error al rechazar al postulante: ' + error.message,
              color: 'error'
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: 'Ocurrio un error general al rechazar al postulante: ' + error.message,
          color: 'error'
        })
      }
      this.$store.state.loading_screen = false
    }
  }
}
</script>