<template>
  <v-row no-gutters>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="comentarios"
        :loading="load"
        :search="search"
        class="elevation-1 my-3 mx-md-2 mx-0"
      >
        <template v-slot:top>
          <div class="d-flex align-center py-2 px-4">
            <v-text-field
              v-model="search"
              prepend-icon="fas fa-search"
              label="Buscar"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              title="Refrescar"
              :disabled="load"
              icon
              @click="get_comentarios()"
            >
              <v-icon>fas fa-sync</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="primary"
            title="Editar"
            class="mr-2"
            small
            @click="editar(item)"
          >
            fas fa-pen
          </v-icon>
          <Confirmar
            :icon_button="true"
            :small="true"
            :texto="`¿Desea eliminar el comentario de la búsqueda ${item.convocatoria}?`"
            icon="fas fa-trash"
            color="error"
            title="Eliminar"
            @action="eliminar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto my-3"
            max-width="450"
            border="left"
            dense
            text
            type="warning"
          >
            No se encontraron comentarios para el postulante
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="dialog"
      width="450"
      scrollable
    >
      <v-card>
        <v-card-title>
          Editar comentario
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-textarea
              v-model="comentario.comentario"
              rows="3"
              hide-details
              autofocus
              auto-grow
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar
            clase="ml-4"
            @action="guardar()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { db } from '../firebase'
import Confirmar from './utils/Confirmar'

export default {
  data () {
    return {
      load: false,
      dialog: false,
      search: '',
      comentario: {},
      comentarios: [],
      headers: [
        { text: 'ID', value: 'convocatoria', sortable: true },
        { text: 'Búsqueda', value: 'busqueda', sortable: true },
        { text: 'Comentario', value: 'comentario', sortable: false },
        { text: 'Etapa', value: 'etapa', sortable: true },
        { text: 'Periodo', value: 'periodo', sortable: true },
        { text: 'Usuario', value: 'usuario', sortable: true },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false },
      ],
    }
  },
  created() {
    this.get_comentarios()
  },
  props: {
    postulante_id: String
  },
  computed: {
    ...mapState(['user'])
  },
  components: {
    Confirmar
  },
  methods: {
    async guardar () {
      this.$store.state.loading_screen = true
      try {
        const comentario = this.comentario.comentario
        const usuario = this.user.nombre + ' ' + this.user.apellido
        await db.collection('usuarios').doc(this.postulante_id).collection('comentarios').doc(this.comentario.convocatoria).update({
          comentario: comentario,
          usuario: usuario
        })
          .then (() => {
            // actualiza el comentario en el array
            let coment = this.comentarios.find(com => com.convocatoria == this.comentario.convocatoria)
            coment.comentario = comentario
            coment.usuario = usuario
            this.dialog = false
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
      this.$store.state.loading_screen = false
    },
    async editar (item) {
      this.comentario = await JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    async eliminar (item) {
      this.$store.state.loading_screen = true
      try {
        await db.collection('usuarios').doc(this.postulante_id).collection('comentarios').doc(item.convocatoria).delete()
          .then (() => {
            // elimina el comentario del array
            const index = this.comentarios.indexOf(item)
            this.comentarios.splice(index, 1)
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
      this.$store.state.loading_screen = false
    },
    async get_comentarios () {
      this.comentarios = []
      this.load = true
      try {
        await db.collection('usuarios').doc(this.postulante_id).collection('comentarios').get()
          .then ((res) => {
            res.forEach((doc) => {
              this.comentarios.push(doc.data())
            })
          })
          .catch((error) => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      }
      this.load = false
    }
  }
}
</script>