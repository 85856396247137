<template>
  <v-dialog
      v-model="dialog"
      width="450"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="ml-4 mb-2"
        >
          Avanzar de etapa
          <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">
          Avanzar de etapa
        </v-card-title>
        <v-card-text class="body-1">
          <v-row>
            <v-col cols="12">
              Seleccione la etapa de la búsqueda <strong>{{ convocatoria.busqueda }}</strong> a la que quiere avanzar al postulante <strong>{{ postulante.nombre }} {{ postulante.apellido }}</strong>
            </v-col>
            <v-col cols="12">
              <v-form ref="form">
                <v-select
                  v-model="etapa_siguiente"
                  :rules="[rules.required]"
                  :items="convocatoria.etapas"
                  label="Siguiente etapa"
                  validate-on-blur
                ></v-select>
              </v-form>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model.trim="comentario_texto"
                label="Comentario"
                rows="3"
                hide-details
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-4"
            @click.stop="dialog = false"
          >
            Cancelar
          </v-btn>
          <Confirmar 
            @action="avanzar_etapa()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { db } from '../../firebase'
import { mapState } from 'vuex'
import { format_date } from '../../utils'
import Confirmar from './Confirmar.vue'

export default {
  data () {
    return {
      dialog: false,
      bloquear: false,
      postulacion: null,
      comentario: null,
      comentario_texto: '',
      etapa_siguiente: '',
      rules: { required: value => !!value || 'Campo requerido' }
    }
  },
  props: {
    id_conv: String,
    convocatoria: Object,
    postulante: Object,
    p_rechazado: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['user']),
    rechazado: {
      get () {
        return this.p_rechazado
      },
      set (value) {
        this.$emit('update:p_rechazado', value)
      }
    }
  },
  components: {
    Confirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        // obtiene la postulacion
        this.postulacion = this.postulante.postulaciones.find(post => post.convocatoria == this.id_conv && post.estado != 'Cancelada por el postulante')
        // obtiene el comentario
        this.$store.state.loading_screen = true
        await db.collection('usuarios').doc(this.postulante.id).collection('comentarios').doc(this.id_conv).get()
          .then((doc) => {
            if (doc.exists) {
              this.comentario = doc.data()
              this.comentario_texto = doc.data().comentario
            } else {
              this.comentario = null
              this.comentario_texto = ''
            }
          })
          .catch((error) => {
            this.comentario = null
            this.comentario_texto = ''
          })
        this.$store.state.loading_screen = false
      } else {
        this.comentario = null
        this.comentario_texto = ''
        this.etapa_siguiente = ''
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async avanzar_etapa () {
      if (this.$refs.form.validate()) {
        this.$store.state.loading_screen = true
        try {
          // obtiene la etapa siguiente
          await db.collection('etapas').doc(this.etapa_siguiente).get()
            .then(async (res) => {
              if (res.exists) {

                // actualzia (o crea) el comentario en caso que el texto no sea nulo
                if (this.comentario_texto) {
                  try {
                    // si el comentario no existe lo crea
                    if (!this.comentario) {
                      this.comentario = {
                        busqueda: this.convocatoria.busqueda,
                        periodo: format_date(this.convocatoria.fecha.desde) + ' - ' + format_date(this.convocatoria.fecha.hasta),
                        postulante_id: this.postulante.id,
                        convocatoria: this.id_conv,
                        usuario: this.user.nombre + ' ' + this.user.apellido
                      }
                    }
                    // cambia el comentario por el nuevo
                    this.comentario.comentario = this.comentario_texto
                    // cambia la etapa del comentario actual por la etapa de la que lo avanza
                    this.comentario.etapa = this.convocatoria.etapas.find(etapa => etapa.value == this.etapa_siguiente).text
                    // guarda el comentario en la coleccion del usuario
                    await db.collection('usuarios').doc(this.postulante.id).collection('comentarios').doc(this.id_conv).set(this.comentario)
                      .then(() => {
                        // todo ok
                      })
                      .catch((error) => {
                        this.$store.dispatch('show_snackbar', {
                          text: 'Ocurrio un error al actualizar el comentario del postulante: ' + error.message,
                          color: 'error'
                        })
                      })
                  } catch (error) {
                    this.$store.dispatch('show_snackbar', {
                      text: 'Ocurrio un error general al actualizar el comentario del postulante: ' + error.message,
                      color: 'error'
                    })
                  }
                }
                
                // actualiza al usuario
                try {
                  await db.collection('usuarios').doc(this.postulante.id).get()
                    .then(async (doc) => {
                      if (doc.exists) {
                        let postulaciones = doc.data().postulaciones
                        const nombre_etapa = this.convocatoria.etapas.find(etapa => etapa.value == this.etapa_siguiente).text
                        postulaciones.find(post => post.convocatoria == this.id_conv && post.estado !== 'Cancelada por el postulante').etapa = nombre_etapa
                        // si el usuario esta rechazado actualiza tambien su estado en la coleccion usuarios
                        if (this.rechazado) {
                          postulaciones.find(post => post.convocatoria === this.id_conv && post.estado !== 'Cancelada por el postulante').estado = 'En proceso de selección'
                        }
                        // actualiza los datos del usuario
                        await db.collection('usuarios').doc(this.postulante.id).update({ postulaciones: postulaciones })
                          .then(async () => {
                            // si el usuario esta rechazado actualiza su estado a no rechazado en los postulantes dentro de la convocatoria
                            if (this.rechazado) {
                              await db.collection('convocatorias').doc(this.id_conv).collection('postulantes').doc(this.postulante.id).update({ estado: true })
                                .then(async () => {
                                  this.dialog = false
                                  this.rechazado = false
                                  this.$store.dispatch('show_snackbar', {
                                    text: 'Cambios guardados correctamente',
                                    color: 'success'
                                  })
                                })
                                .catch((error) => {
                                  this.$store.dispatch('show_snackbar', {
                                    text: 'El postulante fue avanzado pero no desrechazado: ' + error.message,
                                    color: 'warning'
                                  })
                                })
                            } else {
                              this.dialog = false
                              this.$store.dispatch('show_snackbar', {
                                text: 'Cambios guardados correctamente',
                                color: 'success'
                              })
                            }
                          })
                          .catch((error) => {
                            this.$store.dispatch('show_snackbar', {
                              text: 'Ocurrio un error al avanzar de etapa al postulante: ' + error.message,
                              color: 'error'
                            })
                          })
                      } else {
                        this.$store.dispatch('show_snackbar', {
                          text: 'No se encontró al postulante',
                          color: 'error'
                        })
                      }
                    })
                    .catch((error) => {
                      this.$store.dispatch('show_snackbar', {
                        text: 'Ocurrio un error al obtener los datos postulante: ' + error.message,
                        color: 'error'
                      })
                    })
                } catch (error) {
                  this.$store.dispatch('show_snackbar', {
                    text: 'Ocurrio un error general al avanzar de etapa al postulante: ' + error.message,
                    color: 'error'
                  })
                }

              } else {
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontró la siguiente etapa',
                  color: 'error'
                })
              }
            })
            .catch((error) => {
              this.$store.dispatch('show_snackbar', {
                text: 'Ocurrio un error al obtener la siguiente etapa: ' + error.message,
                color: 'error'
              })
            })
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un error general al avanzar de etapa: ' + error.message,
            color: 'error'
          })
        }
        this.$store.state.loading_screen = false
      }
    }
  }
}
</script>